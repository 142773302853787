@import 'styles/variables';

.layout {
  width: 100%;
  margin: 0 auto;
  padding: 100px 0;
  background-color: rgb(0, 0, 0);
}

.container {
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: #{$mobile-width}) {
    max-width: 1680px;
  }
}

.grid {
  display: grid;
  row-gap: 0;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 16px;
  padding: 0 24px;

  @media screen and (min-width: #{$mobile-width}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 24px;
    padding: 0 48px;
  }
}

.row {
  grid-column: span 4 / span 4;
  grid-column-start: 1;

  @media screen and (min-width: #{$mobile-width}) {
    grid-column-start: 5;
  }
}
