@import 'styles/fonts';
@import 'styles/variables';

.inputGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: max-content;
}

.input,
.textArea {
  width: 100%;
  color: $white;
  background: none;
  font-family: $pilatWide;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: normal;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: $white40;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: normal;
  }

  &:hover::placeholder {
    opacity: 0.8;
  }

  &:focus::placeholder {
    color: transparent;
  }
}

.input {
  border: 2px solid $white;
  border-radius: 1000px;
  padding: 14px 16px;
}

.textAreaOuter {
  width: 100%;
  border: 2px solid $white;
  border-radius: 30px;
  padding: 14px 16px;
}

.textArea {
  min-height: 250px;
  border: none;
  resize: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $white;
  }
}

.inputError {
  border: 2px solid $danger;
  color: $danger;

  &::placeholder {
    color: $danger;
  }

  .textArea {
    color: $danger;

    &::placeholder {
      color: $danger;
    }
  }
}

.inputErrorMessage {
  font-family: $pilatWide;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: $danger;
}

.label {
  margin-bottom: 12px;
  color: $white;
  font-family: $pilatWide;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.04em;
}
