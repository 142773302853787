@import 'styles/fonts';
@import 'styles/variables';

.paragraph {
  margin: 0;
  font-family: $ppNeueMachina;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: $white;

  &::selection {
    background: white;
    color: black;
  }

  a {
    color: $teal-300;
    border-color: $teal-300;
    border-bottom: 2px solid;
    text-decoration: none;
    cursor: pointer;
    transition: border-color, color 0.15s cubic-bezier(0, 0, 0.2, 1);

    &:hover {
      color: $white;
      border-color: $white;
      text-decoration: none;
    }

    &:active {
      opacity: 0.6;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
