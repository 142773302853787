@import 'styles/variables';
@import 'styles/fonts';

.footer {
  position: relative;
  height: 818px;

  @media screen and (min-width: #{$mobile-width}) {
    height: 386px;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: $container-width;
  background: none;
  color: $black;
}

.container {
  display: grid;
  row-gap: 0;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 16px;
  padding: 0 24px;

  @media screen and (min-width: #{$mobile-width}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 24px;
    padding: 0 48px;
  }
}

.videoLink {
  display: inline-block;
  padding-top: 40px;
  grid-column-start: 1;

  video {
    width: 120px;
    height: 120px;
    transform: translate(-16px, 25px);
  }

  @media screen and (min-width: #{$mobile-width}) {
    padding-top: 80px;
    grid-column-start: 2;

    video {
      width: 200px;
      height: 200px;
      transform: none;
    }
  }
}

.buildingBlocksGroup {
  grid-column: 1 / span 4;
  padding-top: 64px;

  @media screen and (min-width: #{$mobile-width}) {
    grid-column: 7 / span 3;
    padding-top: 90px;
  }
}

.followUsGroup {
  grid-column: 1 / span 3;
  padding-top: 80px;

  @media screen and (min-width: #{$mobile-width}) {
    grid-column: 10 / span 3;
    padding-top: 90px;
  }
}

.linksTitle {
  margin: 0;
  padding-bottom: 8px;
  font-family: $pilatWide;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.04em;
}

.linksList {
  margin: 0;
  padding: 0;
  font-family: $ppNeueMachina;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  list-style-type: none;

  li {
    padding-top: 12px;
  }

  a {
    color: $black;
    text-decoration: none;
    transition: border-color, color 0.15s cubic-bezier(0, 0, 0.2, 1);
    cursor: pointer;

    &:hover {
      color: $white;
      border-bottom: 2px solid $white;
      text-decoration: none;
    }

    &:active {
      opacity: 0.6;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.copyright {
  display: block;
  max-width: 100%;
  margin-top: 80px;
  font-family: $ppNeueMachina;
  font-weight: 400;
  font-size: 8px;
  line-height: 16px;

  @media screen and (min-width: #{$mobile-width}) {
    max-width: 120px;
    margin-top: 24px;
  }
}
