@import 'styles/fonts';
@import 'styles/variables';

.message {
  font-family: $pilatWide;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  letter-spacing: 0.04em;

  &.error {
    color: $danger;
  }

  &.success {
    color: $white;
  }
}
