@font-face {
  font-family: 'Pilat Wide';
  src: url('../assets/fonts/pilatwide/PilatWide-Regular.woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Pilat Wide';
  src: url('../assets/fonts/pilatwide/PilatWide-Book.woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Pilat Wide';
  src: url('../assets/fonts/pilatwide/PilatWide-Demi.woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Pilat Wide';
  src: url('../assets/fonts/pilatwide/PilatWide-Bold.woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'PPNeueMachina';
  src: url('../assets/fonts/ppneuemachina/PPNeueMachina-Regular.woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'PPNeueMachina';
  src: url('../assets/fonts/ppneuemachina/PPNeueMachina-Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'PPNeueMachina';
  src: url('../assets/fonts/ppneuemachina/PPNeueMachina-Bold.woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'PPNeueMachina';
  src: url('../assets/fonts/ppneuemachina/PPNeueMachina-Black.woff2');
  font-weight: 800;
}

@font-face {
  font-family: 'Diphtong Pixel OT';
  src: url('../assets/fonts/dipthongpixel/DiphtongPixelOT-Regular.otf');
  font-weight: normal;
}

$pilatWide: 'Pilat Wide', sans-serif;
$ppNeueMachina: 'PPNeueMachina', serif;
$dipthong: 'Diphtong Pixel OT', mono;
