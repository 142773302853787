@import 'styles/fonts';
@import 'styles/variables';

.selectContainer {
  position: relative;
}

.selectToggle {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0px 18px 0px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $pilatWide;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.04em;
  cursor: pointer;

  border: 2px solid;
  border-color: $white;
  border-radius: 1000px;
  color: $white;
  background-color: $black;
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
  outline: none;

  &.active {
    border-color: $black;
    background-color: $teal-300;
    color: $black;
    z-index: 2;
  }

  &:hover {
    color: $black;
    background-color: $white;
  }

  &:active {
    background-color: #999999;
    border-color: #999999;
  }

  &:global(.focus-visible) {
    color: $black;
    background-color: $white;
  }
}

.selectListbox {
  display: none;
  position: absolute;

  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  background-color: $teal-300;
  width: 100%;
  margin-top: -25px;
  padding-top: 25px;

  &.active {
    display: block;
    z-index: 1;
  }
}

.listBoxItem {
  padding: 16px 16px 0px 16px;
  font-family: $ppNeueMachina;
  font-size: 12px;
  cursor: pointer;
  margin-top: -1px;
  outline: none;

  &:hover {
    background-color: $black;
    color: $teal-300;
  }

  &:last-child hr {
    display: none;
  }

  &:last-child:hover {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  &:global(.focus-visible) {
    background-color: $black;
    color: $teal-300;
  }
}

.listBoxItemInner {
  padding-bottom: 14px;
}

.selectlabel {
  margin-bottom: 12px;
  color: $white;
  font-family: $pilatWide;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.04em;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: max-content;
}

.divider {
  opacity: 0.6;
  border: 0;
  border-color: $black;
  border-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  margin: 0;
}

.inputError > .selectToggle {
  border: 2px solid $danger;
  color: $danger;
}

.inputErrorMessage {
  font-family: $pilatWide;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: $danger;
}
