@import 'styles/variables';
@import 'styles/fonts';

.container {
  height: 500px;
  position: relative;

  @media screen and (min-width: #{$mobile-width}) {
    height: 660px;
  }
}

.header {
  width: 100%;
  height: 100%;
  max-width: $container-width;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.breadcrumb {
  margin-top: 40px;
  font-family: $ppNeueMachina;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.04em;
  color: $black;

  a {
    color: $black;
    text-decoration: none;
    transition: border-color, color 0.15s cubic-bezier(0, 0, 0.2, 1);
    cursor: pointer;

    &:hover {
      color: $white;
      border-bottom: 2px solid $white;
      text-decoration: none;
    }

    &:active {
      opacity: 0.6;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.heading {
  margin: 0;
  font-family: $pilatWide;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: $black;
}
