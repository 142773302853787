@import 'styles/fonts';
@import 'styles/variables';

.submit {
  width: 100%;
  min-width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $teal-300;
  background: none;
  border: 2px solid $teal-300;
  padding: 18px 16px;
  border-radius: 30px;
  font-family: $pilatWide;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.04em;
  cursor: pointer;

  svg path {
    fill: $teal-300;
  }

  &:disabled {
    color: $teal-400;
    border: 2px solid $teal-400;
    cursor: not-allowed;

    svg path {
      fill: $teal-400;
    }
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus,
  &:not(:disabled):active {
    background: $white;
    color: $black;
    border-color: $white;

    svg path {
      fill: $black;
    }
  }

  &:not(:disabled):focus {
    outline: none;
    box-shadow: none;
  }

  &:not(:disabled):active {
    opacity: 0.6;
  }
}
